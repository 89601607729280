import * as yup from 'yup'

// export const generateValidationSchema = (fields) => {
//     return fields.reduce((acc, field) => {
//         acc[field.name] = field.validation.reduce((fieldAcc, rule) => {
//             if (rule.require) {
//                 fieldAcc = fieldAcc.required(rule.message);
//             }
//             if (rule.min) {
//                 fieldAcc = fieldAcc.min(rule.value, rule.message);
//             }
//             if (rule.max) {
//                 fieldAcc = fieldAcc.max(rule.value, rule.message);
//             }
//             if (rule.match) {
//                 const data = new RegExp(rule.value)
//                 const fieldAcc1 = data.test(rule.value);

//                 // fieldAcc = fieldAcc.matches(data, rule.message);
//                 if (!fieldAcc1) {
//                     // If the string doesn't match the regular expression, handle the error or validation failure
//                     console.error(rule.message);
//                 }
//             }
//             // if (rule.formikType) {
//             //     fieldAcc = yup.formikType() || yup.string()
//             // }
//             if (rule.formikType === 'date') {
//                 fieldAcc = fieldAcc.required(rule.message);
//             }
//             // if (rule.formikType === 'email') {
//             //     fieldAcc = fieldAcc.required(rule.message);
//             // }
//             return fieldAcc;
//         }, yup.string());

//         return acc;
//     }, {});
// };

// export const generateValidationSchema = (fields) => {
//     console.log(fields)
//     return fields?.reduce((acc, field) => {
//         // Determine the field type for Yup
//         let fieldValidation = yup.string(); // Default to string

//         // Check for specific types like number, date, etc.
//         const formikValObj = field?.validation?.find(obj => obj?.hasOwnProperty('formikType'));
//         if (formikValObj?.formikType === 'number') {
//             fieldValidation = yup.number();
//         } else if (formikValObj?.formikType === 'date') {
//             fieldValidation = yup.date();
//         }

//         // Apply validation rules
//         field.validation.forEach(rule => {
//             if (rule.require) {
//                 fieldValidation = fieldValidation.required(rule.message);
//             }
//             if (rule.min) {
//                 fieldValidation = fieldValidation.min(rule.value, rule.message);
//             }
//             if (rule.max) {
//                 fieldValidation = fieldValidation.max(rule.value, rule.message);
//             }
//             if (rule.match) {
//                 const regexPattern = rule.value.replace(/^\/|\/$/g, ''); // Remove leading/trailing slashes
//                 const regex = new RegExp(regexPattern);
//                 fieldValidation = fieldValidation.matches(regex, rule.message);
//             }
//         });

//         acc[field.name] = fieldValidation;
//         return acc;
//     }, {});
// };









export const generateValidationSchema = (fields) => {
    console.log(fields);
    return fields?.reduce((acc, field) => {
        let fieldValidation = yup.string(); // Default to string validation

        const formikValObj = field?.validation?.find(obj => obj?.hasOwnProperty('formikType'));

        // Handle different formik types (number, date, file)
        if (formikValObj?.formikType === 'number') {
            fieldValidation = yup.number();
        } else if (formikValObj?.formikType === 'date') {
            fieldValidation = yup.date();
        } else if (formikValObj?.formikType === 'file') {
            fieldValidation = yup.mixed().test(
                'fileSize',
                'File too large',
                value => !value || (value.size <= 2000000) // Example: max 2MB
            ).test(
                'fileFormat',
                'Unsupported Format',
                value => !value || ['image/jpg', 'image/jpeg', 'image/png', 'application/json'].includes(value.type) // Example: only allow certain file types
            );
        }

        // Apply validation rules
        field?.validation?.forEach(rule => {
            if (rule.require) {
                fieldValidation = fieldValidation.required(rule.message);
            }
            if (rule.min && formikValObj?.formikType !== 'file') { // Skip for files
                fieldValidation = fieldValidation.min(rule.value, rule.message);
            }
            if (rule.max && formikValObj?.formikType !== 'file') { // Skip for files
                fieldValidation = fieldValidation.max(rule.value, rule.message);
            }
            if (rule.match && formikValObj?.formikType !== 'file') { // Skip for files
                const regexPattern = rule.value.replace(/^\/|\/$/g, ''); // Remove leading/trailing slashes
                const regex = new RegExp(regexPattern);
                fieldValidation = fieldValidation.matches(regex, rule.message);
            }
        });

        acc[field.name] = fieldValidation;
        return acc;
    }, {});
};
