export const ROLE_PATH = {
    user: "/user",
    admin: "/admin",
    superAdmin: "/superAdmin",
    // manager: "/manager",
    // teamleader: "/teamleader",
    // executive: "/executive",
    // telecaller: "/telecaller"
}

export const ROLE = {
    superAdmin: "ROLE_ADMIN",
    admin: "ROLE_MODERATOR",
    user: "ROLE_USER",
    // manager: "manager",
    // teamleader: "teamleader",
    // telecaller: "telecaller",
    // executive: "executive",
}

