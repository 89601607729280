
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getApi } from 'services/api';

export const fetchCompanyData = createAsyncThunk('fetchCompanyData', async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
        const response = await getApi(user.roles.includes('ROLE_ADMIN') ? 'api/company/' : `api/company/?createBy=${user.id}`);
        //console.log("Company", user.id)
        return response.data;
    } catch (error) {
        throw error;
    }
});


const getCompanySlice = createSlice({
    name: 'companyData',
    initialState: {
        data: [],
        isLoading: false,
        error: "",
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompanyData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchCompanyData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
                state.error = "";
            })
            .addCase(fetchCompanyData.rejected, (state, action) => {
                state.isLoading = false;
                state.data = [];
                state.error = action.error.message;
            });
    },
});

export default getCompanySlice.reducer;






// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { getApi } from 'services/api';

// export const fetchCompanyData = createAsyncThunk('companyData/fetchCompanyData', async () => {
//     const user = JSON.parse(localStorage.getItem('user'));
//     if (user) {
//         const response = await getApi(user.role === 'superAdmin' ? 'api/company/' : `api/company/?createBy=${user.id}`);
//         return response?.data;
//     } else {
//         throw new Error("User not found in localStorage");
//     }
// });

// const companySlice = createSlice({
//     name: 'companyData',
//     initialState: {
//         data: [],
//         status: 'idle',
//         error: null,
//     },
//     reducers: {},
//     extraReducers: (builder) => {
//         builder
//             .addCase(fetchCompanyData.pending, (state) => {
//                 state.status = 'loading';
//             })
//             .addCase(fetchCompanyData.fulfilled, (state, action) => {
//                 state.status = 'succeeded';
//                 state.data = action.payload;
//             })
//             .addCase(fetchCompanyData.rejected, (state, action) => {
//                 state.status = 'failed';
//                 state.error = action.error.message;
//             });
//     },
// });

// export default companySlice.reducer;
