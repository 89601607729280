import * as yup from 'yup'

export const registerSchema = yup.object({
    username: yup.string().email().required("Email Is required"),
    firstname: yup.string().required("First Name Is required"),
    lastname: yup.string().required("Last Name Is required"),
    address: yup.string().required("Address Is required"),
    mobile: yup.string()
        .matches(/^[0-9]+$/, "Contact Number must be a number")
        .required("Contact Number is required"),
    gst: yup.string()
        .matches(/^[0-9]+$/, "GST Number must be a number")
        .required("GST Number is required"),
    companyname: yup.string().required("Company Name Is required")
})